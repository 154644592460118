import './index.css';

import { PREFERENCE_KEYS, initI18n, reloadI18n, restClient } from '@kinetic-ui/shared';
import { LoadingIndicator } from '@qfa/react-components';
import AppPreload from 'App/components/AppPreload';
import Apollo from 'App/components/AppPreload/Apollo';
import SessionCheck from 'App/components/SessionCheck';
import Theme from 'App/components/Theme';
import {
  BASE_ROUTE,
  COMMUNICATION_LOGS_WINDOW_ROUTE,
  NOTIFICATIONS_WINDOW_ROUTE,
} from 'constants/app';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import { oidcConfig } from './oauth-settings';
import reportWebVitals from './reportWebVitals';
import { setupMocks } from './setupMocks';
import { getUser } from './utils/user';

initI18n();
setupMocks();

const appPromise = import(/* webpackChunkName: "App" */ './App');
const App = React.lazy(() => appPromise);
const NotificationWindow = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationWindow" */ 'pages/NotificationsPage/NotificationWindow'
    )
);

const CommunicationLogsWindow = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CommunicationLogsWindow" */ 'pages/CommunicationLogsPage/CommunicationLogsWindow'
    )
);

const ClearAuthQueryParams = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated && window.location.hash?.includes('state')) {
      window.history.replaceState(
        '',
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, [auth.isAuthenticated]);
  return null;
};

const EnsureAuthenticated = () => {
  const auth = useAuth();
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  if (!auth.isAuthenticated) {
    console.warn('User is not authenticated.');
    return null;
  }
  restClient.interceptors.request.use((res) => {
    const currentUser = getUser();
    if (currentUser) {
      res.headers['Authorization'] = `Bearer ${currentUser.access_token}`;
      res.headers['x-tenant'] = sessionStorage.getItem(PREFERENCE_KEYS.DEFAULT_ORGANIZATION);
    }

    return res;
  });

  return (
    <>
      <ClearAuthQueryParams />
      <Apollo>
        <SessionCheck />
        <Router basename={BASE_ROUTE}>
          <Switch>
            <Route path={`/${NOTIFICATIONS_WINDOW_ROUTE}`}>
              <AppPreload>
                <Theme>
                  <Suspense fallback={<LoadingIndicator />}>
                    <NotificationWindow />
                  </Suspense>
                </Theme>
              </AppPreload>
            </Route>
            <Route path={`/${COMMUNICATION_LOGS_WINDOW_ROUTE}`}>
              <AppPreload>
                <Theme>
                  <Suspense fallback={<LoadingIndicator />}>
                    <CommunicationLogsWindow />
                  </Suspense>
                </Theme>
              </AppPreload>
            </Route>
            <Route path='*'>
              <AppPreload>
                <App />
              </AppPreload>
            </Route>
          </Switch>
        </Router>
      </Apollo>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <EnsureAuthenticated />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

/**
 * listen to window messages for hot-reload
 */
window.addEventListener('message', (e) => {
  const event = e.data.payload?.event;
  // if (event !== 'operations') {
  //   console.log({ event });
  // }
  if (event === 'fastRefreshScheduled') {
    // reload i18n files
    reloadI18n();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
